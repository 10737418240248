export const contents = [
  // {
  //   id: 1,
  //   title: " Zipy",
  //   link: "anydone-zipy",
  //   listing: [
  //     {
  //       id: "0",
  //       title: "Activating Zipy",
  //     },
  //     {
  //       id: "1",
  //       title: "Using Zipy",
  //     },
  //   ],
  // },
  {
    id: 2,
    title: " Introduction",
    link: "anydone-flowcess",
    listing: [
      {
        id: "0",
        title: "Flowcess Intro",
      },
      {
        id: "1",
        title: "Automation Tool",
      },
      {
        id: "2",
        title: "App Directory",
      },
      {
        id: "3",
        title: "Create Flowcess",
      },
      {
        id: "4",
        title: "Run Flowcess",
      },
    ],
  },
  {
    id: 3,
    title: " Automation Tool (AI)",
    link: "anydone-creation",
    listing: [
      {
        id: "0",
        title: "Entity",
      },
      {
        id: "1",
        title: "Intent Collection",
      },
      {
        id: "2",
        title: "Knowledge Base",
      },
      {
        id: "3",
        title: "Knowledge Graph",
      },
      {
        id: "4",
        title: "Automated  Reply",
      },
      {
        id: "5",
        title: "Third Party Model",
      },
   
    
     
    ],
  },
  {
    id: 7,
    title: "Automation Tool (Process)",
    link: "anydone-creation-process",
    listing: [
      {
        id: "0",
        title: " Speech to text ",
      },
      {
        id: "1",
        title: " Text to speech ",
      }
     
    ],
  },
  {
    id: 7,
    title: " Automation Tools",
    link: "automation-tools",
    listing: [
      {
        id: "0",
        title: "Secrets",
      }
     
    ],
  },
  {
    id: 4,
    title: " App Directory",
    link: "anydone-app",
    listing: [
      {
        id: "0",
        title: "Anydone",
      },
      {
        id: "1",
        title: "Chat Plugin",
      },
      {
        id: "2",
        title: "Live Capture",
      },
      {
        id: "3",
        title: "Webhook",
      },
      {
        id: "4",
        title: "Mail",
      },
      {
        id: "5",
        title: "Slack",
      },
    ],
  },
  {
    id: 5,
    title: " Flowcess",
    link: "anydone-create-flowcess",
    listing: [
  
      {
        id: "0",
        title: " Knowledge Base Flowcess",
      },
	  {
        id: "1",
        title: " Knowledge Graph FLowcess",
      },
      {
        id: "2",
        title: " Intent Collection",
      },
      {
        id: "3",
        title: "Automated Replies",
      },
	  {
        id: "4",
        title: "Text Generation",
      },
	  {
        id: "5",
        title: " Text To Speech Flowcess",
      },
      {
        id: "6",
        title: "Run Flowcess",
      },
    ],
  },
  // {
  //   id: 6,
  //   title: "Run Flowcess",
  //   link: "anydone-running-flowcess",
  //   listing: [
  //     // {
  //     //   id: "0",
  //     //   title: "Run Flowcess",
  //     // },
  //   //   {
  //   //     id: "1",
  //   //     title: "Test Flowcess",
  //   //   },
	//   // {
  //   //     id: "2",
  //   //     title: "View Configuration",
  //   //   },
	//   // {
  //   //     id: "3",
  //   //     title: "View Flowcess Log",
  //   //   },
	//   // {
  //   //     id: "4",
  //   //     title: "View Flowcess Output",
  //   //   },
	//   // {
  //   //     id: "5",
  //   //     title: "View Flowcess Information",
  //   //   },
	//   // {
  //   //     id: "6",
  //   //     title: "View Flowcess Instance",
  //   //   },
  //   ],
  // },

 
 
 
];
