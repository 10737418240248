// import React from "react";

export const FAQContents = [
  {
    id: 0,
    title: "Terminologies",
    QuestionAnswers: [
      {
        id: 1,
        question: "What/who is an account owner?",
        answer: [
          `Account owner or workspace owner in anydone has the highest level of administrative privileges within an anydone workspace. The owner is the primary administrator with full control over the workspace, responsible for managing permissions, settings, and configurations.  The owner handles billing and payments and customize features to meet their organizational needs.`,
        ],
      },
      {
        id: 2,
        question: "What/who is a workspace admin?",
        answer: [
          "Workspace admin in anydone is a member with administrative privileges within an anydone workspace, delegated by the Workspace Owner. While they are responsible for managing workspace member accounts and configuring workspace settings, they generally do not have control over billing and overall workspace settings as the Workspace Owner does.",
        ],
      },
      {
        id: 3,
        question: "What/who is a workspace member?",
        answer: [
          `Workspace member in anydone is a user who has access to the anydone workspace and participates in its CoSpace, engages in discussions, shares files, and collaborates on projects and folders <i>(if added)</i>. Their administrative privileges are limited. Workspace members do not have control over workspace settings or permissions, and their access is set by workspace owners and admins.`,
        ],
      },
      {
        id: 4,
        question: "What is CoConnect?",
        answer: [
          `In anydone <a href=${"/messages-and-calls/calls?tabid=0"} target="_blank">CoConnect</a> allows to create, host, or join virtual meetings. You can make video and audio calls, share your screen, chat, and react with different emojis in real-time. You can schedule meetings, invite participants, or join directly from the CoConnet links. It is designed for secured communication, allowing collaboration within or outside your workspace.`,
        ],
      },
      {
        id: 5,
        question: "What is CoSpace?",
        answer: [
          `In anydone <a href=${"/messages-and-calls/groups?tabid=0"} target="_blank">CoSpace</a>, you can add members and collaborate effectively. You can create multiple CoSpaces, choose to make them public or private and send messages, videos, files, and more, ensuring both easy access and effective communication.`,
        ],
      },
      {
        id: 6,
        question: "What is Collab?",
        answer: [
          `<a href=${"/messages-and-calls/collab?tabid=0"} target="_blank">Collab</a> enables anydone users to make interactive collaboration. In Collab, you can add text, images, shapes, draw or sketch. Users can also add projects or folders in Collab and discuss. Also, users can make video and audio calls, brainstorm ideas, and create actionable plans.
`,
        ],
      },
      {
        id: 7,
        question: "What is Paper?",
        answer: [
          `Anydone <a href=${"/project-management/papers?tabid=0"} target="_blank">Paper</a> is a collaborative document within anydone that allows teams to create, edit, and share content in real-time. Users can add images, tables, code, and also write documents using our generative AI built in it. All in all, Paper facilitates collaborative writing, brainstorming, and provides a centralized space to create and manage project-related documents.`,
        ],
      },
      {
        id: 8,
        question: "What is refinement?",
        answer: [
          `Anydone Refinement is a generative AI, designed to enhance communication by helping users in creating clear and effective messages. In addition to its many features, it includes translation feature, making conversations borderless.`,
        ],
      },
      {
        id: 9,
        question: "What is Subject?",
        answer: [
          `Create subjects within <a href=${"/messages-and-calls/groups?tabid=0"} target="_blank">CoSpaces</a>, add specific members from that CoSpace, and make focused, clutter-free conversations. Subjects aims to promote clutter-free discussions through organized chats, helping teams avoid distractions from overwhelming conversations.`,
        ],
      },
      {
        id: 10,
        question: "What is MeetAssist?",
        answer: [
          `<a href=${"/messages-and-calls/meetassist?tabid=0"} target="_blank">MeetAssist</a> is an AI meeting assistant that transcribes conversations, generates summaries and more. It helps to improve communication and makes your meeting more actionable and result oriented.`,
        ],
      },
      {
        id: 11,
        question: "What is Folders in anydone?",
        answer: [
          `Folders in anydone is a way to manage projects in anydone. To effectively manage and complete projects within a time, users can create different folders for projects like Ticket Folder, Sprint Folder, Form Folder, Paper Folder, Flowcess Folder, Report Folder, and more.`,
        ],
      },
    ],
  },
  {
    id: 1,
    title: "Before You Start",
    QuestionAnswers: [
      {
        id: 1,
        question:
          "What credentials will I require to get started with anydone?",
        answer: [
          "When registering on anydone, users must provide their official email address and join our waiting list. Once their request is approved, they can get started.",
        ],
      },
      {
        id: 2,
        question: "How many nations is anydone made available to?",
        answer: [
          `Anydone is available to users worldwide; however, individuals with language-specific settings may face limitations, as we currently only support English.`,
        ],
      },
      {
        id: 3,
        question: "How should I plan roles and permissions within anydone?",
        answer: [
          `Before managing your business with anydone, the workspace owner needs to set permissions, such as assigning admin roles to workspace members, defining what admins and members can do, and specifying who can create projects, tickets, and CoSpaces, as well as who can invite new members to CoSpaces.`,
        ],
      },
      {
        id: 4,
        question: "How many workspaces can I create and manage at once?",
        answer: [
          `The limitations in the number of workspaces created within anydone depend on the pricing plan the user and their organization are registered into. Please visit our <a href=${"https://anydone.com/pricing.html"} target="_blank">Pricing page</a>, to learn more.`,
        ],
      },
    ],
  },

  {
    id: 2,
    title: "Basic Queries",
    QuestionAnswers: [
      {
        id: 1,
        question:
          "Is a Member provided with limited access? Who manages those accesses?",
        answer: [
          "Yes, Members are initially given limited access. However, the individual with Owner or Administrator access can customize Workspace Settings and decide which specific accesses can be granted or restricted.",
        ],
      },
      {
        id: 2,
        question: "Can people from outside the organization join anydone?",
        answer: [
          "Yes, with anydone, you can invite and collaborate with individuals or even companies  outside your organization for a defined period, with customizable permissions and limitations. Only Account Owners and users with Administrator roles have the authority to invite external teams within anydone workspaces.",
        ],
      },
      {
        id: 3,
        question: "Can anydone integrate with other apps?",
        answer: [
          "Yes, anydone integrates with a variety of apps, making it easy to connect and streamline workflows for businesses.",
        ],
      },
      {
        id: 4,
        question: "Can I import tickets and members into anydone?",
        answer: [
          "Yes, you can easily import tickets and members into anydone. This allows you to seamlessly continue your work without interruption.",
        ],
      },
    ],
  },

  {
    id: 3,
    title: "Automation",
    QuestionAnswers: [
      {
        id: 1,
        question: "Does anydone has automation feature?",
        answer: [
          `Yes, anydone includes extensive and powerful <a href=${"/automation"} target="_blank">automation</a> features. It allows you to streamline and automate various business processes, enhancing efficiency and reducing manual tasks.`,
        ],
      },
      {
        id: 2,
        question: "What is AiDone?",
        answer: [
          "AiDone is an ecosystem of automation tools designed to help businesses effectively collaborate and enhance productivity across their teams, regardless of department.",
        ],
      },
      {
        id: 3,
        question: "What is Flowcess?",
        answer: [
          "Flowcess is anydone's no-code automation feature, designed to automate entire business processes seamlessly and effectively.",
        ],
      },
      {
        id: 4,
        question: "What is Auto Assign?",
        answer: [
          `<a href=${"/ticket-management/auto-assign?tabid=0"} target="_blank">Auto Assign</a> is one of anydone's automation features that automatically assigns tickets to members based on their availability and workload.`,
        ],
      },
      {
        id: 5,
        question: "Does anydone automatically generate checklists in tickets?",
        answer: [
          `Yes, anydone can automatically <a href=${"/ticket-management/checklist?tabid=0"} target="_blank">generate checklists</a> in tickets. It creates a list of relevant tasks and steps required to complete an issue.`,
        ],
      },
      {
        id: 6,
        question: "Can anydone automatically generate subtasks within tickets?",
        answer: [
          `Yes, anydone can automatically <a href=${"https://help.anydone.com/ticket-management/subtasks?tabid=0"} target="_blank">generate subtasks</a> within tickets, breaking down issues into a series of manageable tasks for better organization and tracking.`,
        ],
      },
    ],
  },

  {
    id: 4,
    title: "Security",
    QuestionAnswers: [
      {
        id: 1,
        question: "How secure is anydone?",
        answer: [
          "At anydone, we prioritize your data security with robust measures, including encryption protocols and strict access controls. Our security program adheres to ISO 27000, AICPA Trust Service Principles, and NIST standards, continuously adapting to updated guidance and industry best practices.",
        ],
      },
      {
        id: 2,
        question: "Is my data confidential and private?",
        answer: [
          "Yes, at anydone, we consider the confidentiality and privacy of your data as paramount. We adhere to strict data protection policies and comply with applicable privacy regulations. Your information is treated with the utmost confidentiality and is not shared with any third party.",
        ],
      },
      {
        id: 3,
        question: "How does anydone protect my data?",
        answer: [
          "By default, anydone encrypts data at rest and data in transit for all our customers. We further safeguard customers’ data by utilizing Audit Logs and integration with top data prevention (DLP) providers.",
        ],
      },
      {
        id: 4,
        question: "What measures are in place to prevent unauthorized access?",
        answer: [
          "anydone employs multiple layers of security measures to prevent unauthorized access. These include 2-factor authentication, secure access controls, and continuous monitoring of our systems for potential threats. We regularly update our security protocols to avoid emerging risks and ensure the safety of your data.",
        ],
      },
      {
        id: 5,
        question: "Does anydone have end-to-end encryption?",
        answer: [
          "Yes, anydone features end-to-end encryption in CoSpaces, ensuring that messages and files are encrypted before reaching the recipient's device, where only their device can decrypt them, thus ensuring robust privacy and security.",
        ],
      },
      {
        id: 6,
        question:
          "Is my information/ data going to be used for any other purpose(s)?",
        answer: [
          "No, we prioritize the utmost confidentiality and sensitivity for all the information. Your data is handled with the highest level of security to ensure complete confidentiality and protection.",
        ],
      },
    ],
  },
  {
    id: 5,
    title: "Pricing",
    QuestionAnswers: [
      {
        id: 1,
        question: "Is anydone free to use?",
        answer: [
          "Yes, anydone is free to use. After signing up, you can get unlimited access to anydone's features for up to 30 members.",
        ],
      },
      {
        id: 2,
        question: "How many pricing plans does anydone offer?",
        answer: [
          `Anydone offers one plan, making it easy and affordable for any type of business. To learn more, please visit our <a href=${"https://anydone.com/pricing.html"} target="_blank">pricing page</a>.`,
        ],
      },
    ],
  },
];
