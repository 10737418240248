import Project from "../../../../../new/icons/project.svg";
import Automation from "../../../../../new/icons/automation.svg";
const imageUrl="https://storage.googleapis.com/anydone-static-files/assets/help-page/flowcess/"
export const ABcontent = {
	title: "Creating Automation Tool (Process)",
	link: "anydone-creation-process",
	content: [
		
		{
			id: 0,
			title: " Speech to Text ",
            onetitle:`Before creating a speech to text process make sure you have created the model in <a href="anydone-creation?tabid=5">third-party model</a>.`,
			steps:[
                {
                    id:1,
                    title:`Log in to your Anydone account.`
                },
                {
                    id:2,
                    title:`Click on the <b><img src=${Automation} alt="automation" height="12" width="12"/></b> icon`
                },
                {
                    id:3,
                    title:`Select the <b>Process</b> option.`
                },
                {
                    id:4,
                    title:`Click the <b>+</b> button.`
                },
                {
                    id:5,
                    title:`Enter the name and description.`
                },
                {
                    id:6,
                    title:`Under <b>Type</b>, select the <b>Speech to Text</b> option.`
                },
                {
                    id:7,
                    title:`Define the Input <br><br>Now, under <b>Input Mapping</b><br><br>
If you select <b>Dynamic</b>, the Flowcess will prompt the user to provide an audio file, the audio source language, and the text destination language when it runs.<br>
If you select <b>Static</b>, you must specify the audio file, the audio source language, and the text destination language while creating the process.<br> 
<b>Here, we’ve created the process by selecting the Static option.</b><br>
Now, under <b>Output Mapping</b><br>
From the Output Sample click the <b>+</b> button<br>
`,
imageSource: `${imageUrl}process-speech-to-text.gif`,

                },
            ],
            
            text:`			<button style="display: block;  padding: 10px 20px; font-size: 16px; background-color: #007BFF; color: white; border: none; border-radius: 5px; cursor: pointer;">See how it works</button>`,

	
		},
        {
        id:1,
        title:" Text to Speech",
        onetitle:`Before creating a speech to text process make sure you have created the model in <a href="anydone-creation?tabid=5">third-party model</a>.`,

        steps:[
                {
                    id:1,
                    title:`Log in to your Anydone account.`
                },
                {
                    id:2,
                    title:`Click on the <b><img src=${Automation} alt="automation" height="12" width="12"/></b> icon`
                },
                {
                    id:3,
                    title:`Select the <b>Process</b> option.`
                },
                {
                    id:4,
                    title:`Click the <b>+</b> button.`
                },
                {
                    id:5,
                    title:`Enter the name and description.`
                },
                {
                    id:6,
                    title:`Under <b>Type</b>, select the <b>Text to speech</b> option.<br>Now, under <b>Input Mapping</b><br>
If you select <b>Dynamic</b>, the Flowcess will prompt the user to provide an audio file, the audio source language, and the text destination language when it runs.<br>
If you select <b>Static</b>, you must specify the audio file, the audio source language, and the text destination language while creating the process.<br> 
<b>Here, we’ve created the process by selecting the Dynamic option.</b><br><br>
Now, under <b>Output Mapping</b><br><br>
From the Output Sample click the <b>+</b> button
`,
imageSource: `${imageUrl}process-text-to-speech.gif`,
                },
               
            ],
            text:`			<button style="display: block;  padding: 10px 20px; font-size: 16px; background-color: #007BFF; color: white; border: none; border-radius: 5px; cursor: pointer;"><a href="anydone-create-flowcess?tabid=5" style="color:white;">See how it works</a></button>`,
            
        }
 
		

	],
};

