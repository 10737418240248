import Project from "../../../../../new/icons/project.svg";
import Automation from "../../../../../new/icons/automation.svg";


const imageUrl="https://storage.googleapis.com/anydone-static-files/assets/help-page/flowcess/"
export const ARcontent = {
	title: "Creating Automation Tools",
	link: "automation-tools",
	content: [
		
		{
			id: 0,
			title: "Secrets",
			steps:[
                {
                    id:1,
                    title:`Log in to your Anydone account.`,
                },
                {
                    id:2,
                    title:`Click on the <b><img src=${Automation} alt="automation" height="12" width="12"/></b> icon`
                },
                {
                    id:3,
                    title:`Navigate to <b>"Secrets"</b> and click on it.`
                },
                {
                    id:4,
                    title:`Click on the <b>"+"</b> button in the right-side corner.`
                },
                {
                    id:5,
                    title:`Give the secret a name and add a description.`
                },
                {
                    id:6,
                    title:`Add the <b>“API key”</b> under <b>"Value."</b>`
                },
                {
                    id:7,
                    title:`Select the <b>Scope</b> for your secret.`
                },
                {
                    id:8,
                    title:`Lastly, hit the <b>"Create"</b> button.`,
                    imageSource:`${imageUrl}creating-secret.gif`
                }
            ],
       Note:[
        {
            id:1,
            title:` Under Value, you need to add the API key of your preferred model. Currently, we only support OpenAI.`
        }
       ],

	
		},
       
	],
};

